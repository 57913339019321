import React, { useState } from 'react';

function EnhancingYourStorytellingByUsingStunningVisuals() {
    const [inputValue2, setInputValue2] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [loading, setLoading] = useState(false);
    const [numOfImages, setNumOfImages] = useState(1);
    const [color1, setColor1] = useState('');
    const [color2, setColor2] = useState('');
    const [color3, setColor3] = useState('');
    const [artisticStyle, setArtisticStyle] = useState('Realistic');
    const [medium, setMedium] = useState('Oil Painting');
    const [moodTheme, setMoodTheme] = useState('Dark and Eerie');
    const [environment, setEnvironment] = useState('A Bustling Cityscape');
    const [fileName, setFileName] = useState('No file selected');
    const [hoveredTip, setHoveredTip] = useState(null);

    const clearFields2 = () => {
        setInputValue2('');
        setImageSrc('');
        const fileInput = document.getElementById('file-input-2');
        if (fileInput) {
            fileInput.value = ''; // Clear file input
        }
        setFileName('No file selected'); // Reset file name display
    };

    const processInput2 = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('prompt', inputValue2);
        formData.append('no_of_images',numOfImages)
        formData.append('hex_colour_code_1',color1)
        formData.append('hex_colour_code_2',color2)
        formData.append('hex_colour_code_3',color3)
        formData.append('artistic_style',artisticStyle)
        formData.append('medium',medium)
        formData.append('mood_theme',moodTheme)
        formData.append('background',environment)

        try {
            const response = await fetch('/api/generate-image', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const imageData = data.image_data; // Assuming the server response contains 'image_data'

            // Update the image source with base64 data
            setImageSrc(`data:image/png;base64,${imageData}`);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadImage = () => {
        const a = document.createElement('a');
        a.href = imageSrc;
        a.download = 'output-image.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const sharedInputStyle = {
        fontSize: '10px',
        width: '70px',
        marginBottom: '5px',
        padding: '6px',
        border: '1px solid #ccc',
        borderRadius: '5px'
    };

    const tips = {
        numOfImages: "Determines how many variations are generated in one go. (Default: 1)",
        artisticStyle: "A Sets the visual aesthetic-like abstract, realistic, or minimalist.",
        color1: "Specifies exact colors for key elements in your image. Eg.(#ff0000) (Max: 3)",
        medium: "Defines the artistic medium, such as painting, digital art, or photography.",
        moodTheme: "Influences the emotional tone-like serene, dramatic, or vibrant.",
        environment: "Sets the scene, e.g., outdoor landscape, cityscape, or interior space."
    };

    const renderTooltip = (key) => (
        <div className="tooltip" style={{
            visibility: hoveredTip === key ? 'visible' : 'hidden',
            backgroundColor: '#FFFFFF',
            color: '#666666',
            padding: '5px',
            borderRadius: '3px',
            position: 'absolute',
            zIndex: 1,
            width: '250px',
            fontSize: '12px',
            bottom: '50%', // Position above the icon
            left: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            whiteSpace: 'pre-wrap', // Allow multiline text
        }}>
            {tips[key]}
        </div>
    );

    return (
        <div id="case-study-2" className="content">
            <h2 id="exercise-title" style={{ color: '#4479DF' }}>Case Study - Enhancing Your Storytelling with Stunning Visuals</h2>
            <p className="exercise-description">
                This case study explores how generative AI can create stunning visuals that support and enhance your presentation storyline. It demonstrates the ability to generate high-quality images tailored to specific narratives, helping to bring your creative vision to life.
            </p>
            <div className="input-settings-container">
                <div className="left-column">
                    <div className="prompt-section">
                        <label htmlFor="input-2" className="prompt-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Prompt</label>
                        <textarea
                            id="input-2"
                            rows="12"
                            value={inputValue2}
                            onChange={(e) => setInputValue2(e.target.value)}
                            placeholder="Describe the image you would like to create, then hit generate to see the visual result."
                        ></textarea>
                        <div className="button-group">
                            <button className="reset-button" onClick={clearFields2}>Reset</button>
                            <button className="generate-button" onClick={processInput2}>Generate</button>
                        </div>
                    </div>
                    <div className="settings-wrapper">
                        <label htmlFor="settings-section-2" className="settings-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>
                            Image Settings
                        </label>
                        <div className="settings-section">
                            <div className="settings-columns">
                                <div className="left-column">
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="num-of-images" className="setting-label" >
                                            No of Images
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('numOfImages')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <select
                                            id="num-of-images"
                                            value={numOfImages}
                                            onChange={(e) => setNumOfImages(parseInt(e.target.value, 10))}
                                            style={sharedInputStyle}
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="4">4</option>
                                        </select>
                                        {renderTooltip('numOfImages')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="artistic-style" className="setting-label">
                                            Artistic Style
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('artisticStyle')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <select
                                            id="artistic-style"
                                            value={artisticStyle}
                                            onChange={(e) => setArtisticStyle(e.target.value)}
                                            style={sharedInputStyle}
                                        >
                                            <option value="realistic">Realistic</option>
                                            <option value="abstract">Abstract</option>
                                            <option value="cartoonish">Cartoonish</option>
                                            <option value="impressionist">Impressionist</option>
                                            <option value="watercolor">Watercolor</option>
                                        </select>
                                        {renderTooltip('artisticStyle')}
                                    </div>
                                    <div className="setting-item" style={{ position: 'relative', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
        <label htmlFor="color1" className="setting-label" style={{ marginBottom: '5px' }}>
            Hex Colour Code
            <img
                src="info.png"
                alt="Tip Icon"
                style={{ width: '16px', marginLeft: '5px', cursor: 'pointer' }}
                onMouseEnter={() => setHoveredTip('color1')}
                onMouseLeave={() => setHoveredTip(null)}
            />
        </label>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', flexGrow: 1 }}>
        <input
            type="text"
            id="color1"
            value={color1}
            onChange={(e) => setColor1(e.target.value)}
            maxLength="7"
            style={sharedInputStyle}
        />
        <input
            type="text"
            id="color2"
            value={color2}
            onChange={(e) => setColor2(e.target.value)}
            maxLength="7"
            style={sharedInputStyle}
        />
        <input
            type="text"
            id="color3"
            value={color3}
            onChange={(e) => setColor3(e.target.value)}
            maxLength="7"
            style={sharedInputStyle}
        />
    </div>
    {renderTooltip('color1')}
</div>

                                </div>
                                <div className="right-column">
                                    <div className="setting-item">
                                        <label htmlFor="medium" className="setting-label">
                                            Medium
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('medium')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <select
                                            id="medium"
                                            value={medium}
                                            onChange={(e) => setMedium(e.target.value)}
                                            style={sharedInputStyle}
                                        >
                                            <option value="oil painting">Oil Painting</option>
                                            <option value="sketch">Sketch</option>
                                            <option value="digital art">Digital Art</option>
                                            <option value="photorealistic rendering">Photorealistic Rendering</option>
                                        </select>
                                        {renderTooltip('medium')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="mood-theme" className="setting-label">
                                            Theme
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('moodTheme')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <select
                                            id="mood-theme"
                                            value={moodTheme}
                                            onChange={(e) => setMoodTheme(e.target.value)}
                                            style={sharedInputStyle}
                                        >
                                            <option value="dark and eerie">Dark and Eerie</option>
                                            <option value="bright and cheerful">Bright and Cheerful</option>
                                            <option value="mysterious and futuristic">Mysterious and Futuristic</option>
                                        </select>
                                        {renderTooltip('moodTheme')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="environment" className="setting-label">
                                            Background
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('environment')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <select
                                            id="environment"
                                            value={environment}
                                            onChange={(e) => setEnvironment(e.target.value)}
                                            style={sharedInputStyle}
                                        >
                                            <option value="a bustling cityscape">A Bustling Cityscape</option>
                                            <option value="a serene mountain landscape">A Serene Mountain Landscape</option>
                                        </select>
                                        {renderTooltip('environment')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <div className="output-section">
                        <label htmlFor="output-2" className="output-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Output</label>
                        <div id="output-2" className="output-box">
                            {!loading && !imageSrc && (
                                <div className="placeholder">
                                    <div className="placeholder-icon"></div>
                                    <p>No image generated yet!<br />Enter a prompt and click generate.</p>
                                </div>
                            )}
                            {loading && (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                    <p>Generating image...</p>
                                </div>
                            )}
                            {imageSrc && !loading && (
                                <img id="output-image" src={imageSrc} alt="Output Image" />
                            )}
                        </div>
                        <br />
                        <div className="button-group">
                            <button className="download-button" onClick={downloadImage}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnhancingYourStorytellingByUsingStunningVisuals;

