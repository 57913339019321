import React, { useState } from 'react';

function BalanceSheetAnalysiswithChatGPT() {
    const [fileName, setFileName] = useState('No file selected');
    const [fileError, setFileError] = useState('');
    const [inputValue3, setInputValue3] = useState('');
    const [outputValue3, setOutputValue3] = useState('');
    const [temperature3, setTemperature3] = useState(0.7);
    const [maxLength3, setMaxLength3] = useState(100);
    const [frequencyPenalty3, setFrequencyPenalty3] = useState(0.0);
    const [topP3, setTopP3] = useState(1.0);
    const [stopSequences3, setStopSequences3] = useState('');
    const [presencePenalty3, setPresencePenalty3] = useState(0.0);

    const [hoveredTip, setHoveredTip] = useState(null); // State to track which tip is being hovered

    const clearFields3 = () => {
        setInputValue3('');
        setOutputValue3('');
        document.getElementById('file-input-3').value = '';
        setFileName('No file selected');
        setFileError('');
    };

    const processInput3 = async () => {
        const formData = new FormData();
        formData.append('file', document.getElementById('file-input-3').files[0]);
        formData.append('prompt', inputValue3);
        formData.append('temperature', temperature3);
        formData.append('max_length', maxLength3);
        formData.append('frequency_penalty', frequencyPenalty3);
        formData.append('top_p', topP3);
        formData.append('stop_sequences', stopSequences3);
        formData.append('presence_penalty', presencePenalty3);

        try {
            const response = await fetch('/api/process-file', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setOutputValue3(data.response); // Adjust based on your API response structure
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFileChange3 = (event) => {
        setFileName(event.target.files[0] ? event.target.files[0].name : 'No file selected');
    };

    const tips = {
        temperature: "Controls randomness; higher values result in more diverse outputs, while lower values make the output more focused. (Default: 1.0)",
        maxLength: "Defines the maximum length of the generated output. (Default: 2048)",
        frequencyPenalty: "Applies a penalty to frequently used tokens, encouraging more diverse output. (Default: 0.0)",
        topP: "Limits the output to tokens with cumulative probability above this value. (Default: 1.0)",
        stopSequences: "Allows you to specify sequences that will cause the generation to stop.",
        presencePenalty: "Encourages the model to talk about new topics by penalizing repeated phrases or concepts. (Default: 0.0)"
    };

    const renderTooltip = (key) => (
        <div className="tooltip" style={{
            visibility: hoveredTip === key ? 'visible' : 'hidden',
            backgroundColor: '#FFFFFF',
            color: '#666666',
            padding: '5px',
            borderRadius: '3px',
            position: 'absolute',
            zIndex: 1,
            width: '250px',
            fontSize: '12px',
            bottom: '35%', // Position above the icon
            left: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            whiteSpace: 'pre-wrap', // Allow multiline text
        }}>
            {tips[key]}
        </div>
    );

    return (
        <div id="case-study-3" className="content">
            <h2 id="exercise-title" style={{ color: '#4479DF' }}>Case Study - Balance Sheet Analysis with ChatGPT</h2>
            <p className="exercise-description">
            This case study demonstrates how generative AI can assist in analyzing financial statements, particularly balance sheets. It shows how AI can extract and interpret key financial metrics, offering insights into a company's financial health.

            </p>
            <div className="input-settings-container">
                <div className="left-column">
                    <div className="prompt-section">
                        <label htmlFor="input-3" className="prompt-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Prompt</label>
                        <textarea
                            id="input-3"
                            rows="12"
                            value={inputValue3}
                            onChange={(e) => setInputValue3(e.target.value)}
                            placeholder="Describe the financial analysis you need, then hit generate to see the summary."
                        ></textarea>
                        <div className="button-group">
                            <button className="reset-button" onClick={clearFields3}>Reset</button>
                            <button className="generate-button" onClick={processInput3}>Generate</button>
                        </div>
                    </div>
                    <div className="settings-wrapper">
                        <label htmlFor="settings-section-3" className="settings-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>LLM Settings</label>
                        <div className="settings-section">
                            <div className="settings-columns">
                                <div className="left-column">
                                    <div className="setting-item">
                                        <label htmlFor="temperature" style={{ display: 'flex', alignItems: 'center' }}>
                                            Temperature
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('temperature')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="temperature" name="temperature" max="1" step="0.1" value={temperature3}
                                            onChange={(e) => setTemperature3(parseFloat(e.target.value))} />
                                        {renderTooltip('temperature')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="max-length" style={{ display: 'flex', alignItems: 'center' }}>
                                            Max Length
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('maxLength')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="max-length" name="max-length" max="2048" value={maxLength3}
                                            onChange={(e) => setMaxLength3(parseInt(e.target.value, 10))} />
                                        {renderTooltip('maxLength')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="frequency-penalty" style={{ display: 'flex', alignItems: 'center' }}>
                                            Frequency Penalty
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('frequencyPenalty')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="frequency-penalty" name="frequency-penalty" min="-2" max="2" step="0.1" value={frequencyPenalty3}
                                            onChange={(e) => setFrequencyPenalty3(parseFloat(e.target.value))} />
                                        {renderTooltip('frequencyPenalty')}
                                    </div>
                                </div>
                                <div className="right-column">
                                    <div className="setting-item">
                                        <label htmlFor="top-p" style={{ display: 'flex', alignItems: 'center' }}>
                                            Top P
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('topP')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="top-p" name="top-p" max="1" min="0" step="0.1" value={topP3}
                                            onChange={(e) => setTopP3(parseFloat(e.target.value))} />
                                        {renderTooltip('topP')}
                                    </div>
                                    <div className="setting-item" style={{ opacity: 0.3 }}>
                                        <label htmlFor="stop-sequences" style={{ display: 'flex', alignItems: 'center' }}>
                                            Stop Sequences
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('stopSequences')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="text" id="stop-sequences" name="stop-sequences" value={stopSequences3}
                                            onChange={(e) => setStopSequences3(e.target.value)} />
                                        {renderTooltip('stopSequences')}
                                    </div>
                                    <div className="setting-item">
                                        <label htmlFor="presence-penalty" style={{ display: 'flex', alignItems: 'center' }}>
                                            Presence Penalty
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('presencePenalty')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="presence-penalty" name="presence-penalty" min="-2" max="2" step="0.1" value={presencePenalty3}
                                            onChange={(e) => setPresencePenalty3(parseFloat(e.target.value))} />
                                        {renderTooltip('presencePenalty')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="file-upload-container">
                            <button className="browse-button" onClick={() => document.getElementById('file-input-3').click()}>Browse</button>
                            <span className="file-upload-label" style={{ color: '#B8B8B8' }}>{fileName}</span>
                            <input type="file" id="file-input-3" style={{ display: 'none' }} onChange={handleFileChange3} />
                            {fileError && <p className="error-message" style={{ color: 'red' }}>{fileError}</p>} {/* Display error message */}
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <div className="output-section">
                        <label htmlFor="output-3" className="output-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Output</label>
                        <textarea id="output-3" rows="26" readOnly value={outputValue3} placeholder="The AI will generate a financial summary based on your input."></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BalanceSheetAnalysiswithChatGPT;

