import React, { useState } from 'react';

function QnAwithChatGPT() {
  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');
  const [temperature, setTemperature] = useState(0.7);
  const [maxLength, setMaxLength] = useState(100);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [topP, setTopP] = useState(1.0);
  const [stopSequences, setStopSequences] = useState('');
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [hoveredTip, setHoveredTip] = useState(null); // State to track which tip is being hovered

  const clearFields = () => {
    setInputValue('');
    setOutputValue('');
  };

  const processInput = async () => {
    const formData = new FormData();
    formData.append('prompt', inputValue);
    formData.append('temperature', temperature);
    formData.append('max_length', maxLength);
    formData.append('frequency_penalty', frequencyPenalty);
    formData.append('top_p', topP);
    formData.append('stop_sequences', stopSequences);
    formData.append('presence_penalty', presencePenalty);

    try {
      const response = await fetch('/api/qna', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setOutputValue(data.response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const tips = {
    temperature: "Controls randomness; higher values result in more diverse outputs, while lower values make the output more focused. (Default: 1.0)",
    maxLength: "Defines the maximum length of the generated output. (Default: 2048)",
    frequencyPenalty: "Applies a penalty to frequently used tokens, encouraging more diverse output. (Default: 0.0)",
    topP: "Limits the output to tokens with cumulative probability above this value. (Default: 1.0)",
    stopSequences: "Allows you to specify sequences that will cause the generation to stop.",
    presencePenalty: "Encourages the model to talk about new topics by penalizing repeated phrases or concepts. (Default: 0.0)"
  };

  const renderTooltip = (key) => (
    <div className="tooltip" style={{
        visibility: hoveredTip === key ? 'visible' : 'hidden',
        backgroundColor: '#FFFFFF',
        color: '#666666',
        padding: '5px',
        borderRadius: '3px',
        position: 'absolute',
        zIndex: 1,
        width: '250px',
        fontSize: '12px',
        bottom: '35%', /* Position above the icon */
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        whiteSpace: 'pre-wrap', /* Allow multiline text */
        marginBottom: '10px' /* Add space below the tooltip */
    }}>
      {tips[key]}
    </div>
  );

  return (
    <div id="case-study-1" className="content">
      <h2 id="exercise-title" style={{ color: '#4479DF' }}>Case Study - Q&A with ChatGPT</h2>
      <p className="exercise-description">This case study demonstrates the use of generative AI to answer a wide range of questions across various topics. It showcases how AI can understand and respond to user queries in a coherent and informative manner, providing valuable insights and information.</p>
      
      <div className="input-settings-container">
        <div className="left-column">
          <div className="prompt-section">
            <label htmlFor="input" className="prompt-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Prompt</label>
            <textarea id="input" rows="12" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Enter your question or topic, then hit generate to see the AI's response."></textarea>
            <div className="button-group">
              <button className="reset-button" onClick={clearFields}>Reset</button>
              <button className="generate-button" onClick={processInput}>Generate</button>
            </div>
          </div>
          <div className="settings-wrapper">
            <label htmlFor="settings-section" className="settings-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>LLM Settings</label>
            <div className="settings-section">
              <div className="settings-columns">
                <div className="left-column">
                  <div className="setting-item">
                    <label htmlFor="temperature" className="setting-label">
                      Temperature
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('temperature')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="number" id="temperature" name="temperature" max="1" step="0.1" value={temperature}
                      onChange={(e) => setTemperature(parseFloat(e.target.value))} />
                    {renderTooltip('temperature')}
                  </div>
                  <div className="setting-item">
                    <label htmlFor="max-length" className="setting-label">
                      Max Length
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('maxLength')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="number" id="max-length" name="max-length" max="2048" value={maxLength}
                      onChange={(e) => setMaxLength(parseInt(e.target.value, 10))} />
                    {renderTooltip('maxLength')}
                  </div>
                  <div className="setting-item">
                    <label htmlFor="frequency-penalty" className="setting-label">
                      Frequency Penalty
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('frequencyPenalty')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="number" id="frequency-penalty" name="frequency-penalty" min="-2" max="2" step="0.1" value={frequencyPenalty}
                      onChange={(e) => setFrequencyPenalty(parseFloat(e.target.value))} />
                    {renderTooltip('frequencyPenalty')}
                  </div>
                </div>
                <div className="right-column">
                  <div className="setting-item">
                    <label htmlFor="top-p" className="setting-label">
                      Top P
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('topP')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="number" id="top-p" name="top-p" max="1" min="0" step="0.1" value={topP}
                      onChange={(e) => setTopP(parseFloat(e.target.value))} />
                    {renderTooltip('topP')}
                  </div>
                  <div className="setting-item" style={{ opacity: 0.3 }}>
                    <label htmlFor="stop-sequences" className="setting-label">
                      Stop Sequences
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('stopSequences')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="text" id="stop-sequences" name="stop-sequences" value={stopSequences}
                      onChange={(e) => setStopSequences(e.target.value)} />
                    {renderTooltip('stopSequences')}
                  </div>
                  <div className="setting-item">
                    <label htmlFor="presence-penalty" className="setting-label">
                      Presence Penalty
                      <img
                        src="info.png"
                        alt="Tip Icon"
                        style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                        onMouseEnter={() => setHoveredTip('presencePenalty')}
                        onMouseLeave={() => setHoveredTip(null)}
                      />
                    </label>
                    <input type="number" id="presence-penalty" name="presence-penalty" min="-2" max="2" step="0.1" value={presencePenalty}
                      onChange={(e) => setPresencePenalty(parseFloat(e.target.value))} />
                    {renderTooltip('presencePenalty')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="output-section">
            <label htmlFor="output" className="output-label" style={{ color: '#4479DF', fontWeight: 'bold', fontSize: '16px' }}>Output</label>
            <textarea id="output" rows="26" readOnly value={outputValue} placeholder="The AI will generate a detailed answer based on your input."></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnAwithChatGPT;

