import React, { useState } from 'react';

function CustomerChurnwithChatGPT() {
    const [fileName, setFileName] = useState('No file selected');
    const [inputValue4, setInputValue4] = useState('');
    const [outputValue4, setOutputValue4] = useState('');
    const [temperature4, setTemperature4] = useState(0.7);
    const [maxLength4, setMaxLength4] = useState(100);
    const [frequencyPenalty4, setFrequencyPenalty4] = useState(0.0);
    const [topP4, setTopP4] = useState(1.0);
    const [stopSequences4, setStopSequences4] = useState('');
    const [presencePenalty4, setPresencePenalty4] = useState(0.0);
    const [hoveredTip, setHoveredTip] = useState(null); // State to track which tip is being hovered

    const clearFields4 = () => {
        setInputValue4('');
        setOutputValue4('');
    };

    const processInput4 = async () => {
        const formData = new FormData();
        formData.append('file', document.getElementById('file-input-4').files[0]);
        formData.append('prompt', inputValue4);
        formData.append('temperature', temperature4);
        formData.append('max_length', maxLength4);
        formData.append('frequency_penalty', frequencyPenalty4);
        formData.append('top_p', topP4);
        formData.append('stop_sequences', stopSequences4);
        formData.append('presence_penalty', presencePenalty4);

        try {
            const response = await fetch('/api/process-file', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setOutputValue4(data.response); // Adjust based on your API response structure
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleFileChange4 = (event) => {
        setFileName(event.target.files[0] ? event.target.files[0].name : 'No file selected');
    };

    const downloadOutput4 = () => {
        const blob = new Blob([outputValue4], { type: "text/plain;charset=utf-8" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "output.txt";
        link.click();
    };

    const tips = {
        temperature: "Controls randomness; higher values result in more diverse outputs, while lower values make the output more focused. (Default: 1.0)",
        maxLength: "Defines the maximum length of the generated output. (Default: 2048)",
        frequencyPenalty: "Applies a penalty to frequently used tokens, encouraging more diverse output. (Default: 0.0)",
        topP: "Limits the output to tokens with cumulative probability above this value. (Default: 1.0)",
        stopSequences: "Allows you to specify sequences that will cause the generation to stop.",
        presencePenalty: "Encourages the model to talk about new topics by penalizing repeated phrases or concepts. (Default: 0.0)"
    };

    const renderTooltip = (key) => (
        <div className="tooltip" style={{
            visibility: hoveredTip === key ? 'visible' : 'hidden',
            backgroundColor: '#FFFFFF',
            color: '#666666',
            padding: '5px',
            borderRadius: '3px',
            position: 'absolute',
            zIndex: 1,
            width: '250px',
            fontSize: '12px',
            bottom: '120%', // Position above the icon
            left: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            whiteSpace: 'pre-wrap', // Allow multiline text
        }}>
            {tips[key]}
        </div>
    );

    return (
        <div id="case-study-4" className="content">
            <h2 id="exercise-title" style={{ color: '#4479DF' }}>Case Study - Customer Churn Analysis with ChatGPT</h2>
            <p className="exercise-description">This case study focuses on the application of AI in analyzing customer churn. It highlights how AI can process customer data, identify patterns, and predict which customers are likely to leave, enabling businesses to take proactive measures to retain them.
            </p>
            <div className="input-settings-container">
                <div className="left-column">
                    <div className="prompt-section">
                        <label htmlFor="input-4" className="prompt-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Prompt</label>
                        <textarea id="input-4" rows="12" value={inputValue4} onChange={(e) => setInputValue4(e.target.value)} placeholder="Describe the customer data or analysis you need, then hit generate to see the insights."></textarea>
                        <div className="button-group">
                            <button className="reset-button" onClick={clearFields4}>Reset</button>
                            <button className="generate-button" onClick={processInput4}>Generate</button>
                        </div>
                    </div>
                    <div className="settings-wrapper">
                        <label htmlFor="settings-section-4" className="settings-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>LLM Settings</label>
                        <div className="settings-section">
                            <div className="settings-columns">
                                <div className="left-column">
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="temperature" className="setting-label">
                                            Temperature
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('temperature')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="temperature" name="temperature" max="1" step="0.1" value={temperature4}
                                            onChange={(e) => setTemperature4(parseFloat(e.target.value))} />
                                        {renderTooltip('temperature')}
                                    </div>
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="max-length" className="setting-label">
                                            Max Length
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('maxLength')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="max-length" name="max-length" max="2048" value={maxLength4}
                                            onChange={(e) => setMaxLength4(parseInt(e.target.value, 10))} />
                                        {renderTooltip('maxLength')}
                                    </div>
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="frequency-penalty" className="setting-label">
                                            Frequency Penalty
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('frequencyPenalty')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="frequency-penalty" name="frequency-penalty" min="-2" max="2" step="0.1" value={frequencyPenalty4}
                                            onChange={(e) => setFrequencyPenalty4(parseFloat(e.target.value))} />
                                        {renderTooltip('frequencyPenalty')}
                                    </div>
                                </div>
                                <div className="right-column">
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="top-p" className="setting-label">
                                            Top P
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('topP')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="top-p" name="top-p" max="1" min="0" step="0.1" value={topP4}
                                            onChange={(e) => setTopP4(parseFloat(e.target.value))} />
                                        {renderTooltip('topP')}
                                    </div>
                                    <div className="setting-item" style={{ position: 'relative', opacity: 0.3 }}>
                                        <label htmlFor="stop-sequences" className="setting-label">
                                            Stop Sequences
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('stopSequences')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="text" id="stop-sequences" name="stop-sequences" value={stopSequences4}
                                            onChange={(e) => setStopSequences4(e.target.value)} />
                                        {renderTooltip('stopSequences')}
                                    </div>
                                    <div className="setting-item" style={{ position: 'relative' }}>
                                        <label htmlFor="presence-penalty" className="setting-label">
                                            Presence Penalty
                                            <img
                                                src="info.png"
                                                alt="Tip Icon"
                                                style={{ width: '16px', marginLeft: '10px', cursor: 'pointer' }}
                                                onMouseEnter={() => setHoveredTip('presencePenalty')}
                                                onMouseLeave={() => setHoveredTip(null)}
                                            />
                                        </label>
                                        <input type="number" id="presence-penalty" name="presence-penalty" min="-2" max="2" step="0.1" value={presencePenalty4}
                                            onChange={(e) => setPresencePenalty4(parseFloat(e.target.value))} />
                                        {renderTooltip('presencePenalty')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="file-upload-container">
                            <button className="browse-button" onClick={() => document.getElementById('file-input-4').click()}>Browse</button>
                            <span className="file-upload-label" style={{ color: '#B8B8B8' }}>{fileName}</span>
                            <input type="file" id="file-input-4" style={{ display: 'none' }} onChange={handleFileChange4} />
                        </div>
                    </div>
                </div>
                <div className="right-column">
                    <div className="output-section">
                        <label htmlFor="output" className="output-label" style={{ color: '#4479DF', fontWeight: 'bold' }}>Output</label>
                        <textarea id="output-4" rows="26" readOnly value={outputValue4} placeholder="The AI will provide insights based on the customer data you described."></textarea>
                        <div className="button-group">
                            <button className="download-button" onClick={downloadOutput4}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerChurnwithChatGPT;

