import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

// Import your components for each case study
import QnAwithChatGPT from './components/QnAwithChatGPT';
import Enhancingyourstorytellingbyusingstunningvisuals from './components/Enhancingyourstorytellingbyusingstunningvisuals';
import BalanceSheetAnalysiswithChatGPT from './components/BalanceSheetAnalysiswithChatGPT';
import CustomerChurnwithChatGPT from './components/CustomerChurnwithChatGPT';

function App() {
  return (
    <Router>
      <MainContainer />
    </Router>
  );
}

function MainContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeCaseStudy, setActiveCaseStudy] = useState(1); // Default to 1 for QnAwithChatGPT

  useEffect(() => {
    switch (location.pathname) {
      case '/QnAwithChatGPT':
      case '/': // Root URL should also default to QnAwithChatGPT
        setActiveCaseStudy(1);
        break;
      case '/Enhancingyourstorytellingbyusingstunningvisuals':
        setActiveCaseStudy(2);
        break;
      case '/BalanceSheetAnalysiswithChatGPT':
        setActiveCaseStudy(3);
        break;
      case '/CustomerChurnwithChatGPT':
        setActiveCaseStudy(4);
        break;
      default:
        setActiveCaseStudy(1); // Default to QnAwithChatGPT for any unknown path
    }
  }, [location]);

  const getButton = (caseNumber, label, path) => (
    <div
      className="exercise-card"
      onClick={activeCaseStudy === caseNumber ? null : () => navigate(path)}
    >
      {label}
    </div>
  );

  return (
    <div className="main-container">
      <div className="sidebar">
        <img src="/zerocode courses logo white 1.png" alt="ZeroCodeLearning Logo" className="sidebar-logo" />
        <h2 className="sidebar-subheading">Prompt Engineering Exercises</h2>
        <hr className="divider" />
        
        {/* Sidebar logic based on the active route */}
        {location.pathname === '/' || location.pathname === '/QnAwithChatGPT' ? (
          getButton(1, 'Q&A', '/QnAwithChatGPT')
        ) : null}
        {location.pathname === '/Enhancingyourstorytellingbyusingstunningvisuals' ? (
          getButton(2, 'Visual Storytelling', '/Enhancingyourstorytellingbyusingstunningvisuals')
        ) : null}
        {location.pathname === '/BalanceSheetAnalysiswithChatGPT' ? (
          getButton(3, 'Balance Sheet Analysis', '/BalanceSheetAnalysiswithChatGPT')
        ) : null}
        {location.pathname === '/CustomerChurnwithChatGPT' ? (
          getButton(4, 'Customer Churn', '/CustomerChurnwithChatGPT')
        ) : null}
      </div>

      <div className="container">
        <Routes>
          <Route path="/" element={<QnAwithChatGPT />} />
          <Route path="/QnAwithChatGPT" element={<QnAwithChatGPT />} />
          <Route path="/Enhancingyourstorytellingbyusingstunningvisuals" element={<Enhancingyourstorytellingbyusingstunningvisuals />} />
          <Route path="/BalanceSheetAnalysiswithChatGPT" element={<BalanceSheetAnalysiswithChatGPT />} />
          <Route path="/CustomerChurnwithChatGPT" element={<CustomerChurnwithChatGPT />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

